const mapDataCoordinateSystem = {
  center: [2214755.463119088, 6737554.7896424],
  epsg: 'EPSG:3857',
  srid: 3857,
  verboseName: 'Google Mercator',
};

const mapDefaultZoom = 9;
const mapDefaultMinZoom = 0;
const mapDefaultMaxZoom = 28;

const appName = 'Łódzka Specjalna Strefa Ekonomiczna';
const defaultLanguage = 'pl';
const languages = ['pl', 'en'];

export {
  mapDataCoordinateSystem,
  mapDefaultZoom,
  mapDefaultMinZoom,
  mapDefaultMaxZoom,
  appName,
  defaultLanguage,
  languages,
};
