import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/plugins/i18n';

import { beforeAdminRouteEnter } from '@/assets/js/routerUtility';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: () => import('@/views/MainView'),
    beforeEnter: async (to, from, next) => {
      try {
        if (!from?.name && !to.params?.lid) {
          await store.dispatch('admin/getSettings');
          const layerId = store.get('admin/settings')['GREENFIELDS_LAYERS_SETTING']?.value?.layers?.[i18n.locale];
          if (layerId) next({ ...to, params: { ...to.params?.to, lid: layerId } });
          else next();
        } else next();
      } catch {
        next();
      }
    },
    children: [
      {
        path: '/:lid?',
        name: 'main',
        components: {
          sidebar: () => import('@/components/sidebar/TheSidebarBody'),
        },
      },
      {
        path: '/:lid/:fid',
        name: 'offer',
        components: {
          sidebar: () => import('@/components/sidebar/TheSidebarData'),
        },
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/admin/settings',
    name: 'admin',
    component: () => import('@/views/AdminView'),
    beforeEnter: (to, from, next) => {
      beforeAdminRouteEnter(to, from, next, () => next());
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/admin') {
    next({ name: 'admin' });
  } else {
    next();
  }
});

export default router;
