import { make } from 'vuex-pathify';

const state = {
  isFilterVisible: false,
  isLegendVisible: false,
  mobile: false,
  currentTab: 0,
};

const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  mutations,
};
